var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { name: "cog-fade" },
      on: {
        enter: function($event) {
          return _vm.$emit("entering")
        }
      }
    },
    [
      _vm.visible
        ? _c("div", { staticClass: "cog-confirmation cog-page cog-wrapper" }, [
            _vm.showConfirmationMessage
              ? _c(
                  "div",
                  { staticClass: "cog-confirmation-message" },
                  [
                    _vm.message
                      ? _c("c-content", {
                          staticClass: "cog-confirmation__message",
                          attrs: { content: _vm.message, role: "alert" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("default"),
                    _vm._v(" "),
                    _vm.filteredDocuments
                      ? _c(
                          _vm.filteredDocuments.length > 1 ? "ul" : "div",
                          {
                            tag: "component",
                            staticClass: "cog-confirmation__documents"
                          },
                          _vm._l(_vm.filteredDocuments, function(doc) {
                            return _c(
                              _vm.filteredDocuments.length > 1 ? "li" : "div",
                              { key: doc.link, tag: "component" },
                              [
                                _c(
                                  "a",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "cog-no-underline cog-inline-block",
                                      attrs: {
                                        target: "_blank",
                                        href: doc.link
                                      }
                                    },
                                    _vm.flags.disableConfirmationLinks
                                      ? {
                                          click: $event =>
                                            $event.preventDefault()
                                        }
                                      : {}
                                  ),
                                  [
                                    _c("c-file-icon", {
                                      staticClass: "cog-align",
                                      attrs: {
                                        extension:
                                          doc.type === "adobe-pdf"
                                            ? "pdf"
                                            : "doc"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cog-align cog-underline"
                                      },
                                      [_vm._v(_vm._s(doc.title))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEntryDetails ? _c("hr") : _vm._e()
                  ],
                  2
                )
              : _vm.showRedirectMessage
              ? _c("div", { staticClass: "cog-redirect-message" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.redirectedMessage)),
                    _c(
                      "a",
                      {
                        attrs: { href: _vm.finalRedirectUrl, target: "_blank" }
                      },
                      [_vm._v(_vm._s(_vm.finalRedirectUrl))]
                    )
                  ])
                ])
              : _vm.showAmpRedirectMessage
              ? _c("div", { staticClass: "cog-redirect-message" }, [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: _vm.finalRedirectUrl } },
                    [_vm._v(_vm._s(_vm.finalRedirectUrl))]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }