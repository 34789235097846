<template>
	<transition
		name="cog-fade"
		@enter="$emit('entering')"
	>
		<div v-if="visible" class="cog-confirmation cog-page cog-wrapper">
			<div v-if="showConfirmationMessage" class="cog-confirmation-message">
				<c-content v-if="message" class="cog-confirmation__message" :content="message" role="alert" />
				<slot></slot>
				<component :is="filteredDocuments.length > 1 ? 'ul' : 'div'" v-if="filteredDocuments" class="cog-confirmation__documents">
					<component :is="filteredDocuments.length > 1 ? 'li' : 'div'" v-for="doc in filteredDocuments" :key="doc.link">
						<a target="_blank" :href="doc.link" class="cog-no-underline cog-inline-block" v-on="flags.disableConfirmationLinks ? {click: ($event) => $event.preventDefault()} : {}">
							<c-file-icon class="cog-align" :extension="doc.type === 'adobe-pdf' ? 'pdf' : 'doc'" />
							<span class="cog-align cog-underline">{{ doc.title }}</span>
						</a>
					</component>
				</component>
				<hr v-if="showEntryDetails">
			</div>
			<div v-else-if="showRedirectMessage" class="cog-redirect-message">
				<span>{{ redirectedMessage }}<a :href="finalRedirectUrl" target="_blank">{{ finalRedirectUrl }}</a></span>
			</div>
			<div v-else-if="showAmpRedirectMessage" class="cog-redirect-message">
				<a target="_blank" :href="finalRedirectUrl">{{ finalRedirectUrl }}</a>
			</div>
		</div>
	</transition>
</template>

<script>
	import 'src/style/_motion.scss';
	import CContent from './Content.vue';
	import CFileIcon from './FileIcon.vue';
	import { getSiteUrl } from 'src/util/site-url';

	export default {
		name: 'CConfirmation',
		components: {
			CContent,
			CFileIcon
		},
		inject: ['flags'],
		props: {
			entry: { type: Object, required: true },
			visible: { type: Boolean, default: false },
			includeEntryDetails: { type: Boolean, default: false },
			confirmationMessage: { type: String, default: 'Your response has been recorded.' },
			redirectEnabled: { type: Boolean, default: true },
			redirectUrl: { type: String, default: null },
			documents: { type: Array, default: null },
			action: { type: [Object, String], default: null },
			showUsersRedirected: { type: Boolean, default: true }
		},
		computed: {
			message() {
				const message = this.confirmationMessage.replace(/\r?\n|\r/g, '');
				if (this.flags.disableConfirmationLinks)
					return message.replace(/(<a )href="\[Entry..*?Link]"(.*?>)/g, '$1onclick="return;"$2');
				else
					return message;
			},
			finalRedirectUrl() {
				let url = this.redirectUrl;

				if (!url || !url.trim())
					return '';

				// If external links are not allowed, ensure all redirects go to Cognito Forms
				if (!this.flags.allowExternalLinks && !url.toLowerCase().startsWith(getSiteUrl()))
					return '';

				if (url.includes('?')) {
					const domainPart = url.substring(0, url.indexOf('?') + 1);
					const qsPart = url.substring(domainPart.length);
					const newRedirectUrl = this.entry.toString(domainPart);
					const queryString = this.entry.toString(qsPart, encodeURIComponent);
					const encodedURL = newRedirectUrl + queryString;
					return encodedURL.trim();
				}

				// Replace tokens with values
				url = this.entry.toString(url).trim();

				// Ensure the final redirect URL begins with http(s):// to prevent malicious code from
				// being executed on the client or redirecting using another protocol
				const redirectUrlRegex = new RegExp('^https?://', 'i');

				return redirectUrlRegex.test(url) ? url : null;
			},
			showRedirectMessage() {
				return !this.redirectEnabled && !!this.finalRedirectUrl;
			},
			showAmpRedirectMessage() {
				return this.redirect && window.name.includes('amp_iframe');
			},
			redirect() {
				return this.redirectEnabled && !!this.finalRedirectUrl;
			},
			showEntryDetails() {
				return this.includeEntryDetails && !this.showRedirectMessage;
			},
			showConfirmationMessage() {
				const hasMessageOrDocs = this.confirmationMessage || (this.filteredDocuments && this.filteredDocuments.length > 0);
				return hasMessageOrDocs && !this.showRedirectMessage && !this.redirect;
			},
			filteredDocuments() {
				if (this.flags.workflowEnabled)
					// Workflow forms should use the workflow action's included documents and must be calculated client-side for the preview pane
					return (this.action && this.action.Confirmation && this.documents) ? this.documents.filter(d => this.action.Confirmation.IncludedDocuments.includes(d.number)) : null;
				else
					return this.documents;
			},
			redirectedMessage() {
				return this.showUsersRedirected ? 'Your users will be redirected to ' : '';
			}
		},
		watch: {
			visible: {
				immediate: true,
				handler(isVisible) {
					if (isVisible && this.redirect && !this.showAmpRedirectMessage)
						this.$emit('redirect', this.finalRedirectUrl);
				}
			},
			includeEntryDetails: {
				immediate: true,
				handler() {
					if (this.showEntryDetails || this.flags.workflowEnabled)
						this.$emit('show-entry-details', this.includeEntryDetails);
				}
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-confirmation {

		&__documents {
			margin-top: $gutter-two-thirds;
			list-style: none;

			li {
				margin-bottom: $gutter-half;
			}
		}

		.cog-payment > .cog-section__inner {
			padding-bottom: 0;
		}

		hr {
			margin-top: var(--gutter);
			margin-bottom: 0;
		}
	}
}
</style>